export default {
    emailManagerState: {
        attachments: null,
        allAccounts: null,
        emailResponse: null,
        accountEmail: null,
        unassignedEmailCount: null,
    },
    emailTemplates: [
        {
            id: "blankTemplate",
            name: "Blank Template"
        },
        // {
        //     id: "pensionReportEmailQA",
        //     name: "Pension Report Email QA",
        //     required: ["lor", "ar"]
        // },
        {
            id: "clairesRules",
            name: "Claires Rules"
        },
        {
            id: "applicationReceivedSuccessfully",
            name: "SP991271 - Application received successfully"
        },
        {
            id: "definedBenefits",
            name: "SP991284 - Defined Benefits",
            required: ["lor"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
            name:
                "SP991341 - LOA Covering Email Data Request Provider Unknown Policy Wet Post Follow Up",
            required: ["loa_ukp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
            name:
                "SP991313 - LOA Covering Email Data Request To Provider Chaser Unknown Policy Number",
            required: ["loa_ukp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
            name:
                "SP991312 - LOA Covering Email Data Request Provider Known Policy Number",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderWetSignature",
            name:
                "SP991314 - LOA Covering Email Data Request Provider Wet Signature",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestToProvider",
            name: "SP991285 - LOA Covering Email Data Request To Provider",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestToProviderUnknown",
            name: "SP991286 - LOA Covering Email Data Request To Provider Unknown",
            required: ["loa_ukp"]
        },
        // {
        //     id: "loaRejectedAvivaResolve",
        //     name: "SP991305 - LOA Rejected Aviva Resolve",
        //     required: ["loa_kp"]
        // },
        {
            id: "loaRejectedContactProvider",
            name: "SP991307 - LOA Rejected Contact Provider",
            required: ["loa_kp"]
        },
        {
            id: "loaRejectedNoPolicyNumber",
            name: "SP991308 - LOA Rejected No Policy Number",
            required: ["loa_kp"]
        },
        {
            id: "loaSent",
            name: "SP991304 - LOA Sent",
            required: ["loa_kp"]
        },
        // {
        //     id: "loaSentHardCopy",
        //     name: "SP991310 - LOA Sent Hard Copy",
        //     required: ["loa_kp"]
        // },
        {
            id: "loaSentUpdatedInfo",
            name: "SP991309 - LOA Sent Updated Info",
            required: ["loa_kp"]
        },
        {
            id: "loaWetSignatureNeeded",
            name: "SP991294 - LOA Wet Signature Needed",
            required: ["loa_kp", "loa_kp_ns", "loa_ukp_ns"]
        },
        // {
        //     id: "loaWetSignatureNeededNotProvidedInApp",
        //     name: "SP991295 - LOA Wet Signature Needed Not Provided In App",
        //     required: ["loa_kp"]
        // },
        {
            id: "missingInformation",
            name: "SP991272 - Missing Information"
        },
        {
            id: "missingInformationChaser",
            name: "SP991273 - Missing Information Chaser"
        },
        {
            id: "missingInformationChaserClosed",
            name: "SP991274 - Missing Information Chaser Closed"
        },
        {
            id: "missingInformationReceived",
            name: "SP991275 - Missing Information Received"
        },
        {
            id: "missingInformationStillRequiredClosed",
            name: "SP991277 - Missing Information Still Required Closed"
        },
        {
            id: "missingInformationStillRequiredContinued",
            name: "SP991276 - Missing Information Still Required Continued"
        },
        {
            id: "noResponseFromProvider",
            name: "SP991315 - No Response From Provider"
        },
        {
            id: "noResponseTwoMonths",
            name: "SP991318 - No Response Two Months"
        },
        {
            id: "providerUnableToFindPolicy",
            name: "SP991321 - Provider Unable To Find Policy"
        },
        {
            id: "pensionReportEmail",
            name: "SP991323 - Pension Report Email",
            required: ["lor", "ar"]
        },
        {
            id: "providerConfirmedRetirementIncomeOrFullCash",
            name: "SP991322 - Provider Confirmed Retirement Income Or Full Cash"
        },
        {
            id: "providerConfirmedTransferOut",
            name: "SP991342 - Provider Confirmed Transfer Out"
        },
        {
            id: "providerLORSentDirectToCustomer",
            name: "SP991319 - Provider LOR Sent Direct To Customer"
        },
        {
            id: "providerLORSentToCustomerClosureAfterNoResponse",
            name:
                "SP991320 - Provider LOR Sent To Customer Closure After No Response"
        },
        // {
        //   id: "serviceCompleteDefinedBenefitsPolicyFound",
        //   name: "SP991345 - Service Complete Defined Benefits Policy Found"
        // },
        {
            id: "serviceCompleteSuccessful",
            name: "SP991324 - Service Complete Successful"
        },
        {
            id: "serviceCompleteUnsuccessful",
            name: "SP991344 - Service Complete Unsuccessful"
        },
        {
            id: "searchUnsuccessful",
            name: "SP991278 - Search Unsuccessful"
        },
        {
            id: "signedLOAChaser",
            name: "SP991335 - Signed LOA Chaser",
            required: ["loa_kp"]
        },
        {
            id: "signedLOAChaserPrintedVersion",
            name: "SP991343 - Signed LOA Chaser Printed Version",
            required: ["loa_kp"]
        },
        {
            id: "signatureWordingUpdate",
            name: "SP991432 - Signature Missing from Application"
        },
        {
            id: "signatureMissingFromApplicationChaser",
            name: "SP991433 - Signature Missing from Application Chaser "
        },

        // {
        //   id: 'autoEnrollmentResponse',
        //   name: 'Template 01 - Response for Auto-enrollment part of Claire’s Rules'
        // },
        {
            id: 'claireRulesResponse',
            name: 'Template 02 - Response for Claire’s rules'
        },
        {
            id: 'eRNoCompanyPensionDuringClientEmployment',
            name: 'Template 16 - Email where ER had NO company pension during clients employment:'
        },
        {
            id: 'howToTransferPensionToAviva',
            name: 'Template 17 - How to transfer your pension to Aviva (only to be used when client has specifically asked us about transferring)'
        },
        // {
        //     id: 'knownCivilServicePensionDB',
        //     name: 'Template 09 - Known Civil Service Pension DB Email'
        // },
        // {
        //   id: 'knownDBTeachersPension',
        //   name: 'Template 10 - Known DB Teachers Pension email response'
        // },
        // {
        //     id: 'knownJPACPensionDB',
        //     name: 'Template 11 - Known JPAC Pension DB Email'
        // },
        // {
        //     id: 'knownLGPSPensionDB',
        //     name: 'Template 12 - known LGPS Pension DB Email'
        // },
        // {
        //     id: 'knownNHSPensionDB',
        //     name: 'Template 13 - Known NHS Pension DB Email'
        // },
        {
            id: 'moreInfoFromEmployer',
            name: 'Template 15a - more information from employer'
        },
        {
            id: 'moreInfoFromEmployerChaser',
            name: 'Template 15b - more information from employer chaser (info pulled from 15a)'
        },
        {
            id: 'refundOfContributions',
            name: 'Template 14 - Email for refund of contributions'
        },
        // {
        //   id: 'unknownCivilServicePensionDB',
        //   name: 'Template 08 - Unknown Civil Service Pension DB Email'
        // },
        {
            id: 'unknownDBBlankEmail',
            name: 'Template 07 - Unknown DB - Blank email (this needs to pull through employer, OP details)'
        },
        // {
        //     id: 'unknownDBEducationSettingLGPSOrTeachersPension',
        //     name: 'Template 06 - Unknown DB Education setting LGPS or Teachers pension Email response'
        // },
        {
            id: 'template18_automatedLorUploadEmail',
            name: 'Template 18 - Automated Lor Upload Email'
        },
        {
            id: 'template19_crystallisedPension',
            name: 'Template 19 - Crystallised Pension'
        },
        // {
        //     id: 'template20_pcsLORTemplate',
        //     name: 'Template 20 - PCS LOR Template'
        // },
        {
            id: 'template20_pcsLORTemplate',
            name: 'CD10316 - PCS Unisure LOR Email Template'
        },
        {
            id: 'oisPlatform',
            name: 'CD10327 - PCS OIS LOR Email Template'
        },
        {
            id: "providerNotMatched",
            name: "Template 21 - Provider Not Matched"
        },
        {
            id: "providerNotMatchedChaser",
            name: "Template 22 - Provider Not Matched Chaser"
        },
        // {
        //   id: 'unknownJPACDB',
        //   name: 'Template 05 - Unknown JPAC DB Email'
        // },
        // {
        //   id: 'unknownLGPSDB',
        //   name: 'Template 04 - Unknown LGPS DB Email'
        // },
        // {
        //   id: 'unknownNHSDB',
        //   name: 'Template 03 - NHS DB unknown email'
        // },
    ],
    emailTemplatesWithLetters: [
        {
            id: "blankTemplate",
            name: "Blank Template"
        },
        // {
        //     id: "pensionReportEmailQA",
        //     name: "Pension Report Email QA",
        //     required: ["lor", "ar"]
        // },
        {
            id: "clairesRules",
            name: "Claires Rules"
        },
        {
            id: "applicationReceivedSuccessfully",
            name: "SP991271 - Application received successfully"
        },
        {
            id: "definedBenefits",
            name: "SP991284 - Defined Benefits",
            required: ["lor"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
            name:
                "SP991341 - LOA Covering Email Data Request Provider Unknown Policy Wet Post Follow Up",
            required: ["loa_ukp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
            name:
                "SP991313 - LOA Covering Email Data Request To Provider Chaser Unknown Policy Number",
            required: ["loa_ukp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
            name:
                "SP991312 - LOA Covering Email Data Request Provider Known Policy Number",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestProviderWetSignature",
            name:
                "SP991314 - LOA Covering Email Data Request Provider Wet Signature",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestToProvider",
            name: "SP991285 - LOA Covering Email Data Request To Provider",
            required: ["loa_kp"]
        },
        {
            id: "loaCoveringEmailDataRequestToProviderUnknown",
            name: "SP991286 - LOA Covering Email Data Request To Provider Unknown",
            required: ["loa_ukp"]
        },
        {
            id: "loaRejectedAvivaResolve",
            name: "SP991305 - LOA Rejected Aviva Resolve",
            required: ["loa_kp"]
        },
        {
            id: "loaRejectedContactProvider",
            name: "SP991307 - LOA Rejected Contact Provider",
            required: ["loa_kp"]
        },
        {
            id: "loaRejectedNoPolicyNumber",
            name: "SP991308 - LOA Rejected No Policy Number",
            required: ["loa_kp"]
        },
        {
            id: "loaSent",
            name: "SP991304 - LOA Sent",
            required: ["loa_kp"]
        },
        // {
        //     id: "loaSentHardCopy",
        //     name: "SP991310 - LOA Sent Hard Copy",
        //     required: ["loa_kp"]
        // },
        {
            id: "loaSentUpdatedInfo",
            name: "SP991309 - LOA Sent Updated Info",
            required: ["loa_kp"]
        },
        {
            id: "loaWetSignatureNeeded",
            name: "SP991294 - LOA Wet Signature Needed",
            required: ["loa_kp", "loa_kp_ns", "loa_ukp_ns"]
        },
        {
            id: "loaWetSignatureNeededNotProvidedInApp",
            name: "SP991295 - LOA Wet Signature Needed Not Provided In App",
            required: ["loa_kp"]
        },
        {
            id: "missingInformation",
            name: "SP991272 - Missing Information"
        },
        {
            id: "missingInformationChaser",
            name: "SP991273 - Missing Information Chaser"
        },
        {
            id: "missingInformationChaserClosed",
            name: "SP991274 - Missing Information Chaser Closed"
        },
        {
            id: "missingInformationReceived",
            name: "SP991275 - Missing Information Received"
        },
        {
            id: "missingInformationStillRequiredClosed",
            name: "SP991277 - Missing Information Still Required Closed"
        },
        {
            id: "missingInformationStillRequiredContinued",
            name: "SP991276 - Missing Information Still Required Continued"
        },
        {
            id: "noResponseFromProvider",
            name: "SP991315 - No Response From Provider"
        },
        {
            id: "noResponseTwoMonths",
            name: "SP991318 - No Response Two Months"
        },
        {
            id: "providerUnableToFindPolicy",
            name: "SP991321 - Provider Unable To Find Policy"
        },
        {
            id: "pensionReportEmail",
            name: "SP991323 - Pension Report Email",
            required: ["lor", "ar"]
        },
        {
            id: "providerConfirmedRetirementIncomeOrFullCash",
            name: "SP991322 - Provider Confirmed Retirement Income Or Full Cash"
        },
        {
            id: "providerConfirmedTransferOut",
            name: "SP991342 - Provider Confirmed Transfer Out"
        },
        {
            id: "providerLORSentDirectToCustomer",
            name: "SP991319 - Provider LOR Sent Direct To Customer"
        },
        {
            id: "providerLORSentToCustomerClosureAfterNoResponse",
            name:
                "SP991320 - Provider LOR Sent To Customer Closure After No Response"
        },
        // {
        //   id: "serviceCompleteDefinedBenefitsPolicyFound",
        //   name: "SP991345 - Service Complete Defined Benefits Policy Found"
        // },
        {
            id: "serviceCompleteSuccessful",
            name: "SP991324 - Service Complete Successful"
        },
        {
            id: "serviceCompleteUnsuccessful",
            name: "SP991344 - Service Complete Unsuccessful"
        },
        {
            id: "searchUnsuccessful",
            name: "SP991278 - Search Unsuccessful"
        },
        {
            id: "signedLOAChaser",
            name: "SP991335 - Signed LOA Chaser",
            required: ["loa_kp"]
        },
        {
            id: "signedLOAChaserPrintedVersion",
            name: "SP991343 - Signed LOA Chaser Printed Version",
            required: ["loa_kp"]
        },
        {
            id: "signatureWordingUpdate",
            name: "SP991432 - Signature Missing from Application"
        },
        {
            id: "signatureMissingFromApplicationChaser",
            name: "SP991433 - Signature Missing from Application Chaser "
        },

        // {
        //   id: 'autoEnrollmentResponse',
        //   name: 'Template 01 - Response for Auto-enrollment part of Claire’s Rules'
        // },
        {
            id: 'claireRulesResponse',
            name: 'Template 02 - Response for Claire’s rules'
        },
        {
            id: 'eRNoCompanyPensionDuringClientEmployment',
            name: 'Template 16 - Email where ER had NO company pension during clients employment:'
        },
        {
            id: 'howToTransferPensionToAviva',
            name: 'Template 17 - How to transfer your pension to Aviva (only to be used when client has specifically asked us about transferring)'
        },
        // {
        //     id: 'knownCivilServicePensionDB',
        //     name: 'Template 09 - Known Civil Service Pension DB Email'
        // },
        // {
        //   id: 'knownDBTeachersPension',
        //   name: 'Template 10 - Known DB Teachers Pension email response'
        // },
        // {
        //     id: 'knownJPACPensionDB',
        //     name: 'Template 11 - Known JPAC Pension DB Email'
        // },
        // {
        //     id: 'knownLGPSPensionDB',
        //     name: 'Template 12 - known LGPS Pension DB Email'
        // },
        // {
        //     id: 'knownNHSPensionDB',
        //     name: 'Template 13 - Known NHS Pension DB Email'
        // },
        {
            id: 'moreInfoFromEmployer',
            name: 'Template 15a - more information from employer'
        },
        {
            id: 'moreInfoFromEmployerChaser',
            name: 'Template 15b - more information from employer chaser (info pulled from 15a)'
        },
        {
            id: 'refundOfContributions',
            name: 'Template 14 - Email for refund of contributions'
        },
        // {
        //   id: 'unknownCivilServicePensionDB',
        //   name: 'Template 08 - Unknown Civil Service Pension DB Email'
        // },
        {
            id: 'unknownDBBlankEmail',
            name: 'Template 07 - Unknown DB - Blank email (this needs to pull through employer, OP details)'
        },
        // {
        //     id: 'unknownDBEducationSettingLGPSOrTeachersPension',
        //     name: 'Template 06 - Unknown DB Education setting LGPS or Teachers pension Email response'
        // },
        {
            id: 'template18_automatedLorUploadEmail',
            name: 'Template 18 - Automated Lor Upload Email'
        },
        {
            id: 'template19_crystallisedPension',
            name: 'Template 19 - Crystallised Pension'
        },
        // {
        //     id: 'template20_pcsLORTemplate',
        //     name: 'Template 20 - PCS LOR Template'
        // },
        {
            id: 'template20_pcsLORTemplate',
            name: 'CD10316 - PCS Unisure LOR Email Template'
        },
        {
            id: 'oisPlatform',
            name: 'CD10327 - PCS OIS LOR Email Template'
        },
        {
            id: "providerNotMatched",
            name: "Template 21 - Provider Not Matched"
        },
        {
            id: "providerNotMatchedChaser",
            name: "Template 22 - Provider Not Matched Chaser"
        },
        // {
        //   id: 'unknownJPACDB',
        //   name: 'Template 05 - Unknown JPAC DB Email'
        // },
        // {
        //   id: 'unknownLGPSDB',
        //   name: 'Template 04 - Unknown LGPS DB Email'
        // },
        // {
        //   id: 'unknownNHSDB',
        //   name: 'Template 03 - NHS DB unknown email'
        // },
        {
            id: "letter-9",
            name: "Letter 9 - Information request & LOA - Known"
        }, {
            id: "letter-9a",
            name: "Letter 9a - Information request & LOA - Unknown"
        }, {
            id: "letter-10c",
            name: "Letter 10c - Cover Letter & Blank LOA for member to sign"
        }, {
            id: "letter-11",
            name: "Letter 11 - Information request - Chaser for provider"
        }
    ]
};
